import React, { useState, useEffect } from 'react';
import foods from '../food.json'

const suggestions = [
    'You might be craving for',
    'You just need to grab some',
    'You possibly have an appetite for',
    'You may have developed a yearning for',
    'You have longed for',
    'You hunger',
];

export default function ThirdStep({ foodTiming, cuisine, foodItem, setFoodItem }) {
  
  const [sentence, setSentence] = useState(suggestions[0])
  const randomizeFood = () => {
    const foodList = foods[foods.findIndex(food => food.country===cuisine)][foodTiming.references]
    const newFood = foodList[Math.floor(Math.random()*foodList.length)]
    if(newFood !== foodItem)
      setFoodItem(newFood)
    else
      randomizeFood()
  }
  const randomizeSentence = () => {
      const newSentence = suggestions[Math.floor(Math.random()*suggestions.length)]
      if(newSentence !== sentence)
        setSentence(newSentence)
      else
        randomizeSentence()
  }

  useEffect(() => {
    randomizeFood();
    randomizeSentence();
  }, []);

  return (
    <div className='row'>
      <div className='col'>
        <h3 style={{ margin: '0', textAlign: 'left', fontWeight: '200' }}>
          &#x3e; {sentence} -&nbsp;
          <span style={{ color: '#ffde7e'}}>{foodItem}</span>
        </h3>
      </div>
      <div className='col right'>
        <button className="randomize" onClick={()=>{randomizeFood();randomizeSentence()}}>Change</button>
      </div>
    </div>
  );
}
