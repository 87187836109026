
export default function FirstStep({ cuisine }) {
    return (
        <div className='row'>
          <div className='col'>
            <h3 style={{ margin: '0', textAlign: 'left', fontWeight: '200' }}>&#x3e; Serving {cuisine} Cuisine...</h3>
          </div>
          <div className='col right'>
            <button className="reset" disabled>Change</button>
          </div>
        </div>
    );
}
