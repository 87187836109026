import React, { useState, useEffect } from 'react';
import './App.css';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import ThirdStep from './components/ThirdStep';

const timings = [
  {
    type: 'Breakfast',
    references: 'breakfast'
  },
  {
    type: 'Lunch',
    references: 'dishes'
  },
  {
    type: 'High Tea',
    references: 'breakfast'
  },
  {
    type: 'Dinner',
    references: 'dishes'
  }
]

const cuisines = [
  'Indian',
  'French',
  'Chinese',
  'Japanese',
  'Italian',
  'Greek',
  'Spanish',
  'Lebanese',
  'Turkey',
  'Thai',
  'Mexican',
  'American'
]

function App() {
  const [foodTiming, setFoodTiming] = useState(timings[0])
  const [cuisine, setCuisine] = useState(cuisines[0])
  const [foodItem, setFoodItem] = useState('')
  const reset = () => {
    var hours = new Date().getHours()
    if (hours < 10) {
      setFoodTiming(timings[0]);
    } 
    else if (hours < 15) {
      setFoodTiming(timings[1]);
    } 
    else if (hours < 18) {
      setFoodTiming(timings[2]);
    } 
    else {
      setFoodTiming(timings[3]);
    }
  }
  useEffect(() => {
    reset();
  }, []);
  return (
    <div className='App'>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <h2 style={{ margin: '0', textAlign: 'center', fontWeight: '200' }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-egg-fried" viewBox="0 0 16 16"><path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="#fbbc04" /><path d="M13.997 5.17a5 5 0 0 0-8.101-4.09A5 5 0 0 0 1.28 9.342a5 5 0 0 0 8.336 5.109 3.5 3.5 0 0 0 5.201-4.065 3.001 3.001 0 0 0-.822-5.216zm-1-.034a1 1 0 0 0 .668.977 2.001 2.001 0 0 1 .547 3.478 1 1 0 0 0-.341 1.113 2.5 2.5 0 0 1-3.715 2.905 1 1 0 0 0-1.262.152 4 4 0 0 1-6.67-4.087 1 1 0 0 0-.2-1 4 4 0 0 1 3.693-6.61 1 1 0 0 0 .8-.2 4 4 0 0 1 6.48 3.273z"/></svg> What To Eat?</h2>
          </div>
        </div>
        <FirstStep cuisine={cuisine} />
        <SecondStep timings={timings} foodTiming={foodTiming} setFoodTiming={setFoodTiming} reset={reset} />
        <ThirdStep cuisine={cuisine} foodTiming={foodTiming} foodItem={foodItem} setFoodItem={setFoodItem} />
      </div>
    </div>
  );
}

export default App;
