export default function FirstStep({ timings, foodTiming , setFoodTiming, reset }) {
    const randomizeFood = () => {
        const newStep = timings[Math.floor(Math.random()*timings.length)]
        if(newStep.type !== foodTiming.type)
            setFoodTiming(newStep)
        else
            randomizeFood()
    }
    return (
        <div className='row'>
            <div className='col'>
                <h3 style={{ margin: '0', textAlign: 'left', fontWeight: '200' }}>
                    &#x3e; It's time for -&nbsp;
                    <span style={{ color: '#ffde7e'}}>{foodTiming.type}</span>
                </h3>
            </div>
            <div className='col right'>
                <button className="randomize" onClick={()=>randomizeFood()}>Change</button>
                <button className="reset" onClick={()=>reset()}>Reset</button>
            </div>
        </div>
    );
}
